import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ButtonAction } from "../../../../../../../shared/button/button-action.model";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { RiskDxIcd } from "../dx-models/response/risk-dx-icd.model";

@Component({
  selector: "app-risk-dx-admin-card-header",
  templateUrl: "./dx-admin-card-header.component.html",
  styleUrls: ["./dx-admin-card-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxAdminCardHeaderComponent implements OnInit {
  assignmentItems: ButtonAction[] = [];
  @Input() icdGroup: RiskDxIcd = new RiskDxIcd();
  @Input() isValidated: boolean;
  @Input() isEveOnlyCardsValidationRequired: boolean;
  @Input() isCoderRequiredToReviewAdminEncounter: boolean;
  @Output() onResetAll: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() onInvalid: EventEmitter<any> = new EventEmitter<boolean>();

  ngOnInit() {
    this.initializeAssignmentItems();
  }

  get hccCardStatus(): string {
    if (this.isCoderRequiredToReviewAdminEncounter && !this.isEveOnlyCardsValidationRequired) {
    const hasAdminDiagnoses = this.icdGroup.diagnoses.some(diagnosis => diagnosis.isAdmin);
    const hasEveDiagnoses = this.icdGroup.diagnoses.some(diagnosis => diagnosis.isEve);
    const hasCoderAddedDiagnoses = this.icdGroup.diagnoses.some(diagnosis => diagnosis.isCoderAddedDiagnosis);
    const areAllAdminDiagnosisInvalidated = this.icdGroup.diagnoses
        .filter(diagnosis => diagnosis.isAdmin)
        .every(({ diagnosisStatus, vrcCode }) =>
          (diagnosisStatus === "D" && StringHelper.isAvailable(vrcCode))
        );

    const anyEveOrCoderDiagnosisValidated = this.icdGroup.diagnoses
        .filter(diagnosis => diagnosis.isEve || diagnosis.isCoderAddedDiagnosis)
        .some(({ diagnosisStatus }) =>
          diagnosisStatus === "A" || diagnosisStatus === "V"
        );

    if (hasEveDiagnoses && (hasAdminDiagnoses || hasCoderAddedDiagnoses) && areAllAdminDiagnosisInvalidated && !anyEveOrCoderDiagnosisValidated) {
            this.icdGroup.hccCardValidationStatus = "Invalidated";
          }

    }
    return this.icdGroup.hccCardValidationStatus;
  }
  get showGroupText(): boolean {
    return StringHelper.isAvailable(this.icdGroup?.diagnoses[0]?.groupText);
  }
  get displayHeaderText(): string {
    if (this.icdGroup && this.icdGroup.diagnoses && this.icdGroup.diagnoses.length > 0) {
      const groupText = this.icdGroup.diagnoses[0].groupText;
      const [firstPart, secondPart] = this.splitText(groupText);
      return `[${firstPart}] - ${secondPart}`;
    }
    return "";
  }

  get displayModelName(): string {
    return this.icdGroup?.rxHCC ? "RxHCC" : "HCC";
  }

  splitText(text: string): string[] {
    if (!text) {
      return [];
    }

    const groupText = text.split("-");
    const icdCode = groupText.slice(0, 2).join("-").trim();
    const description = groupText.slice(2).join("-").trim();

    return [icdCode, description];
  }
  private initializeAssignmentItems(): void {
    this.assignmentItems.push(
      new ButtonAction({
          name: "Invalidate All",
          action: () => this.invalidateAll(),
      }),
      new ButtonAction({
          name: "Reset All",
          action: () => this.resetAll(),
      })
  );
  }
  invalidateAll(): void {
      this.onInvalid.emit();
  }
  resetAll(): void {
    this.onResetAll.emit();
}

  get hccbtnClass(): string {
  const status = this.icdGroup.hccCardValidationStatus;
  switch (status) {
    case "Validated":
      return "success";
    case "Invalidated":
      return "danger";
    default:
      return "default";
  }
}
}
