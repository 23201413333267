import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { filter, finalize, map } from "rxjs/operators";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { ChaseDetailState } from "../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../chase-detail/chase-detail-state.service";
import { RiskDxDeleteDiagnosis } from "../dx-models/request/risk-dx-delete.modal";
import { RiskDxValidationDiagnosis } from "../dx-models/request/risk-dx-validation-diagnosis.model";
import { RiskDxValidation } from "../dx-models/request/risk-dx-validation.model";
import { RiskDxDiagnosis } from "../dx-models/response/risk-dx-diagnosis.model";
import { RiskDxIcd } from "../dx-models/response/risk-dx-icd.model";
import { RiskDxActionType } from "../dx-models/risk-dx-action-type.enum";
import { RiskDxService } from "../risk-dx.service";

@Component({
  selector: "app-risk-dx-admin-card",
  templateUrl: "./dx-admin-card.component.html",
  styleUrls: ["./dx-admin-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxAdminCardComponent implements OnInit {
  @Input() chaseId: number;
  @Input() isReadOnly: boolean;
  @Input() addNewCoder: boolean;
  @Input() icdGroup: RiskDxIcd;
  @Input() riskChaseState: ChaseDetailState;
  @Input() encounterId: number;
  @Input() masterEveEncounterId: number;
  @Output() icdGroupChange: EventEmitter<RiskDxIcd> = new EventEmitter();
  @Output() hideNetNewHccCard: EventEmitter<boolean> = new EventEmitter();
  addNewDx = false;
  private isCoderRequiredToReviewAdminEncounter: boolean;
  private isEveOnlyCardsValidationRequired: boolean;
  private isSaving = false;
  private sink = new SubSink();

  get shouldDisableControl(): boolean {
    return this.isSaving || this.isReadOnly;
  }

  get isAllDxAddedByCoder(): boolean {
    return this.icdGroup?.diagnoses?.every(item => item.isCoderAddedDiagnosis);
  }

  constructor(private readonly riskService: RiskDxService,
              private readonly riskDxService: RiskDxService,
              private readonly messagingService: MessagingService,
              private readonly changeDetector: ChangeDetectorRef,
              private readonly chaseDetailStateService: ChaseDetailStateService) {
  }

  ngOnInit(): void {
    this.sink.add(
      this.riskService.resetValidationButtons$
        .pipe(
          filter(icdGroup => this.icdGroup.hccId === icdGroup.hccId && this.icdGroup.dxType === icdGroup.dxType))
        .subscribe(() => this.emitValidationChange(null)),
      this.chaseDetailStateService.state
        .pipe(
          map(state => {
          this.isCoderRequiredToReviewAdminEncounter = state?.projectConfiguration?.reviewAdminEncountersAndDiagnoses === "1";
          this.isEveOnlyCardsValidationRequired = state?.projectConfiguration?.eveOnlyCardsValidationRequired === "1";
          })
          )
        .subscribe()

    );
  }

  addNewDiagnosis(): void {
    this.addNewDx = true;
  }

  removeDx(): void {
    this.addNewDx = false;
    this.hideNetNewHccCard.emit();
  }

  removeDiagnosis($event): void {
    const selectedDx = this.getDeletedDxForValidation($event);

    const deleteRequest = new RiskDxDeleteDiagnosis({
      chaseId: this.riskChaseState.chaseId,
      entityId: selectedDx[0].entityId,
    });
    this.riskService.deleteDiagnosis(deleteRequest)
      .pipe(
        map(() => this.emitValidationChange(true)),
        finalize(() => {
          this.riskDxService.refreshDxReview();
          this.setValidationButtonsState(false);
        })
      ).subscribe();
  }

  saveNewDiagnosis($event): void {
    let selectedDx;
    if ($event.isDxAddedFromNewCard) {
      $event.encounterId = this.encounterId;
      selectedDx = [$event];
    } else {
      this.icdGroup.diagnoses.push($event);
      selectedDx = this.getSelectedDxForValidation($event);
    }
    const validationRequest = this.createRiskValidationRequest(selectedDx);
    this.riskService.setDxValidationStatus(validationRequest)
      .pipe(
        map(() => this.emitValidationChange(true)),
        finalize(() => {
          this.riskDxService.addCard(false);
          this.riskDxService.refreshDxReview();
          this.setValidationButtonsState(false);
        })
      ).subscribe();
  }

  onValid($event): void {
    const selectedDx = this.getSelectedDxForValidation($event);
    const validationRequest = this.createRiskValidationRequest(selectedDx);
    validationRequest.riskDxActionType = RiskDxActionType.Valid;
    this.riskService.setDxValidationStatus(validationRequest)
      .pipe(
        map(() => this.emitValidationChange(true)),
        finalize(() => {
          this.riskDxService.refreshDxReview();
          this.setValidationButtonsState(false);
        })
      ).subscribe();
  }

  onInvalid($event?: any): void {
    this.selectAllDiagnoses();
    const selectedDxs = $event != null
    ? this.getSelectedDxForInvalidation($event)
    : this.getAllDxForInvalidation();
    const validationRequest = this.createRiskInValidationRequest(selectedDxs);
    validationRequest.riskDxActionType = RiskDxActionType.Invalid;
    this.riskService.setDxValidationStatus(validationRequest)
      .pipe(
        map(() => this.emitValidationChange(false)),
        finalize(() => {
          this.riskDxService.refreshDxReview();
          this.setValidationButtonsState(false);
        })
      )
      .subscribe();
  }

  onResetAll($event?: any): void {
    this.selectAllDiagnoses();
    const selectedDxs = $event != null
    ? this.getSelectedDxForInvalidation($event)
    : this.getAllDxForInvalidation();
    const validationRequest = this.createRiskValidationRequest(selectedDxs);
    validationRequest.riskDxActionType = RiskDxActionType.ResetAll;
    this.riskService.setDxValidationStatus(validationRequest)
      .pipe(
        map(() => this.emitValidationChange(false)),
        finalize(() => {
          this.riskDxService.refreshDxReview();
          this.setValidationButtonsState(false);
        })
      )
      .subscribe();
  }

  private selectAllDiagnoses(): void {
    this.icdGroup.diagnoses = this.icdGroup.diagnoses
      .map(diagnosis => {
        return { ...diagnosis, isSelected: true };
      });
  }

  private getDeletedDxForValidation(selectedItem: RiskDxDiagnosis): RiskDxDiagnosis[] {
    return this.icdGroup.diagnoses
      .filter(diagnosis =>
        selectedItem.code === diagnosis.code &&
        selectedItem.dosFrom === diagnosis.dosFrom &&
        selectedItem.providerName === diagnosis.providerName &&
        selectedItem.pageNumber === diagnosis.pageNumber
      );
  }

  private getSelectedDxForValidation(riskDxDiagnosis: RiskDxDiagnosis): RiskDxDiagnosis[] {
    return this.icdGroup.diagnoses
      .filter(diagnosis =>
        riskDxDiagnosis.code === diagnosis.code &&
        riskDxDiagnosis.dosFrom === diagnosis.dosFrom &&
        riskDxDiagnosis.providerName === diagnosis.providerName &&
        riskDxDiagnosis.pageNumber === diagnosis.pageNumber
      );
}

  private getSelectedDxForInvalidation(riskDxDiagnosis: RiskDxDiagnosis): RiskDxDiagnosis[] {
    return this.icdGroup.diagnoses
      .filter(diagnosis =>
        riskDxDiagnosis.code === diagnosis.code &&
        riskDxDiagnosis.dosFrom === diagnosis.dosFrom &&
        riskDxDiagnosis.providerName === diagnosis.providerName &&
        riskDxDiagnosis.pageNumber === diagnosis.pageNumber
      );
  }

  private getAllDxForInvalidation(): RiskDxDiagnosis[] {
    return this.icdGroup.diagnoses;
}

  private createRiskValidationRequest(riskDxDiagnosis: RiskDxDiagnosis[]): RiskDxValidation {
    const dxActionRequests = riskDxDiagnosis
      .map(diagnosis => new RiskDxValidationDiagnosis({
        diagnosisEndDate: diagnosis.dosThrough,
        diagnosisFromDate: diagnosis.dosFrom,
        diagnosisId: diagnosis.diagnosisId,
        diagnosisPageNumber: diagnosis.pageNumber,
        documentPageId: diagnosis.documentPageId,
        encounterId: diagnosis.encounterId,
        entityId: diagnosis.entityId,
        hcc: this.icdGroup?.hccId.trim(),
        icdCode: diagnosis.code,
        isCoderAddedDiagnosis: diagnosis.isCoderAddedDiagnosis,
        isEveData: true,
        isSelected: diagnosis.isSelected,
        modelId: this.icdGroup?.modelId,
        providerName: diagnosis.providerName,
        vrcCode: diagnosis.vrcCode,
        providerSourceAliasId: diagnosis.providerSourceAliasId,
        isEve : diagnosis.isEve,
      }));

    return new RiskDxValidation(this.chaseId, dxActionRequests);
  }
  private createRiskInValidationRequest(riskDxDiagnosis: RiskDxDiagnosis[]): RiskDxValidation {
    const dxActionRequests = riskDxDiagnosis
      .map(diagnosis => new RiskDxValidationDiagnosis({
        diagnosisEndDate: diagnosis.dosThrough,
        diagnosisFromDate: diagnosis.dosFrom,
        diagnosisId: diagnosis.diagnosisId,
        diagnosisPageNumber: diagnosis?.pageNumber || 1,
        documentPageId: diagnosis.documentPageId,
        encounterId: diagnosis.encounterId,
        entityId: diagnosis.entityId,
        hcc: this.icdGroup?.hccId.trim(),
        icdCode: diagnosis.code,
        isCoderAddedDiagnosis: diagnosis.isCoderAddedDiagnosis,
        isEveData: true,
        isSelected: diagnosis.isSelected,
        modelId: this.icdGroup?.modelId,
        providerName: diagnosis.providerName,
        vrcCode: diagnosis.vrcCode,
        providerSourceAliasId: diagnosis.providerSourceAliasId,
        isEve : diagnosis.isEve,
      }));

    return new RiskDxValidation(this.chaseId, dxActionRequests);
  }
  private setValidationButtonsState(isSaving: boolean = true): void {
    this.isSaving = isSaving;
    this.changeDetector.markForCheck();
  }

  private emitValidationChange(isValid: boolean | null): void {
    if (this.icdGroup) {
      this.icdGroup.isValidated = isValid;
      this.icdGroupChange.emit(this.icdGroup);
    }
  }

}
